<template lang="">
  <div>
    <RidersListIndex
      :fst-id="`franchise-riders-page`"
      :qso="qso"
      :pageTitle="title"
      :endpoint="endpoint"
      :filterItems="filterOptions"
    />
  </div>
</template>
<script>
import RidersListIndex from '@/composites/rider/index/Index.vue'
import { useEndpoints } from '@/composables'
export default {
  name: 'ViewOrganizationUserRiders',
  components: {
    RidersListIndex,
  },
  data() {
    return {
      title: this.$t(
        'components.orgUserDetailsManagement.diveIntoTab.rider.headline'
      ),
      endpoint: null,
      qso: { append: '', prepend: '' },
      filterOptions: [],
    }
  },
  async created() {
    this.generateEndPoint()
    await this.generateFilterOptions()
  },
  methods: {
    generateEndPoint() {
      this.endpoint =
        useEndpoints.rider.index() + `?franchise_user=${this.$route.params.id}&`
    },
    async generateFilterOptions() {
      const filterableFleets = []
      const url = useEndpoints.fleet.indexOfStaff(this.$route.params.id)
      await this.$http.get(url).then(({ data }) => {
        if (data.data.length) {
          data.data.forEach((fleet) => {
            filterableFleets.push({ text: fleet.name, value: fleet.id })
          })
        }
      })
      this.filterOptions = [
        {
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        },
        {
          type: 'checkbox',
          key: 'fraud_status',
          input: [
            { text: 'Normal', value: 'N' },
            { text: 'Suspicious', value: 'S' },
            { text: 'Block Listed', value: 'B' },
          ],
          title: 'Fraud Status',
        },
        {
          type: 'checkbox',
          key: 'trip_status',
          input: [
            { text: 'Completed', value: 'N' },
            { text: 'Reservation', value: 'R' },
            { text: 'On Rent', value: 'T' },
            { text: 'On Pay', value: 'P' },
            { text: 'On Feedback', value: 'F' },
          ],
          title: 'Trip Status',
        },
        {
          type: 'radio',
          key: 'user_status',
          input: [
            { text: 'On Hold', value: 'H' },
            { text: 'Verified', value: 'A' },
            { text: 'Unverified', value: 'D' },
          ],
          title: 'Account Status',
        },
        {
          key: 'card_added',
          type: 'toggle',
          input: {
            checkedValue: 'true',
            uncheckedValue: 'false',
            checkedLabel: 'Yes',
            uncheckedLabel: 'No',
          },
          title: 'Card Added',
        },
        {
          key: 'balance',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.symbol,
            max: 9999,
            min: -9999,
            minQs: 'min_balance', // query string for min value
            maxQs: 'max_balance', // query string for max value
          },
          title: 'Balance',
        },
      ]
    },
  },
}
</script>
<style lang=""></style>
